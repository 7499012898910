.testimonial-slider {
    width: 100%;
    overflow: hidden;
  }
  
  .testimonial-container {
    display: flex;
    width: 300%;
  }
  
  .testimonial-item {
    width: 33.33%;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .client-image, .company-logo {
    max-width: 100px;
    margin-bottom: 10px;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
    border: none;
    cursor: pointer;
  }
  
  .pagination-dot.active {
    background-color: #333;
  }
.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 24px;
    cursor: pointer;
  }

  .top {
    position: fixed;
    min-width: 36px;
    height: 36px;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    border-radius: 8px;
    color: var(--heading-font-color);
    background-color: var(--background-alt-color);
    cursor: pointer;
    transition: all .25s ease
}
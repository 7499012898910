/*
.App {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  transition: background-color 0.25s ease-in-out;
}

.title {
  color: var(--primary-text-color);
  margin-bottom: 50px;
}

.box {
  padding: 20px;
  border-radius: 10px;
  background-color: var(--foreground-color);
  color: var(--secondary-text-color);
}
*/
:root {
  /* Modo claro */
  --background-light: #ffffff;
  --text-light: #333333;
  --primary-light: #007bff;
  --secondary-light: #f8f9fa;
  --border-light: #dee2e6;

  /* Modo oscuro */
  --background-dark: #1a1a1a;
  --text-dark: #ffffff;
  --primary-dark: #4da6ff;
  --secondary-dark: #2c2c2c;
  --border-dark: #444444;
}

/* Aplicar modo claro por defecto */
body {
  --background: var(--background-light);
  --text: var(--text-light);
  --primary: var(--primary-light);
  --secondary: var(--secondary-light);
  --border: var(--border-light);
}

/* Aplicar modo oscuro cuando se active */
body.dark-mode {
  --background: var(--background-dark);
  --text: var(--text-dark);
  --primary: var(--primary-dark);
  --secondary: var(--secondary-dark);
  --border: var(--border-dark);
}
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Jost', Arial, Helvetica, sans-serif;
}

:root {
  --background-color: #f6f6f6;
  --foreground-color: #003cff;
  --primary-text-color: #0a0a0a;
  --secondary-text-color: #ffffff;
  --toggle-bg: #f0eb9d;
  --toggle-fg: #ffd000;
}

[data-theme="dark"] {
  --background-color: #0a0a0a;
  --foreground-color: #567aff;
  --primary-text-color: #f6f6f6;
  --secondary-text-color: #0a0a0a;
  --toggle-bg: #283452;
  --toggle-fg: #00a6ff;
}

body {
  margin: 0;
  /*font-family: 'Jost', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-family: 'Jost', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Helvetica, Arial,
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
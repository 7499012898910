.computer-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.computer-frame {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.screen {
  position: absolute;
  top: 5.5%;
  left: 12.5%;
  right: 12.5%;
  bottom: 32%;
  overflow: hidden;
}

.laptop .screen {
  top: 6.5%;
  left: 13.5%;
  right: 13.5%;
  bottom: 18%;
}
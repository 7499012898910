.cookieDialog {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 9999;
  }
  
  .cookieCard {
    background-color: #ffffff;
    color: #333333;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    padding: 20px;
    max-width: 400px;
  }
  
  .first {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .closeIcon {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #333333;
  }
  
  .tips {
    margin-bottom: 15px;
    font-size: 14px;
  }
  
  .setCookie {
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .twoBtns {
    display: flex;
    justify-content: space-between;
  }
  
  .acceptAll, .rejectAll {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .acceptAll {
    background-color: #007bff;
    color: #ffffff;
  }
  
  .rejectAll {
    background-color: #f8f9fa;
    color: #333333;
    border: 1px solid #dee2e6;
  }
  
  .checkboxGroup {
    margin-bottom: 15px;
  }
  
  .checkboxLabel {
    display: block;
    margin-bottom: 5px;
  }
  
  /* Estilos para modo oscuro */
  :global(.dark) .cookieCard {
    /*background-color: #333333;*/
    background-color: #374151;
    color: #ffffff;
  }
  
  :global(.dark) .closeIcon {
    color: #ffffff;
  }
  
  :global(.dark) .setCookie {
    color: #5ca8ff;
  }
  
  :global(.dark) .acceptAll {
    background-color: #5ca8ff;
  }
  
  :global(.dark) .rejectAll {
    /*background-color: #4a4a4a;*/
    background-color: #1F2937;
    color: #ffffff;
    border-color: #666666;
  }